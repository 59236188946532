/* eslint-disable @typescript-eslint/no-var-requires, no-use-before-define */

const React = require('react')
const RootElementProvider =
  require('@talentinc/gatsby-theme-ecom/components/Providers/RootElement').default
const TopResumeTheme = require('./src/styles').default

exports.wrapRootElement = ({ element }) => {
  return (
    <RootElementProvider theme={TopResumeTheme}>{element}</RootElementProvider>
  )
}
